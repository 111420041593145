import { motion } from "framer-motion";

export default function AnimationWave() {
  return (
    <div>
      <svg
        style={{
          position: "absolute",
          width: "100%",
          bottom: "-1",
          zIndex: "99",
        }}
        viewBox="0 0 1200 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 1" clip-path="url(#clip0_19_2)">
          <motion.path
            animate={{ x: 25 }}
            transition={{
              ease: "linear",
              duration: "2",
              repeat: Infinity,
              repeatType: "reverse",
            }}
            id="FillWave"
            d="M-22.3656 163.937C22.4964 175.587 67.9332 179.976 113.589 169.252C150.198 160.653 183.553 143.492 217.182 127.609C263.15 105.898 311.116 85.6573 362.496 80.4453C425.961 74.0063 483.486 94.4703 542.981 112.548C589.963 126.825 637.642 138.803 687.017 141.103C742.061 141.235 793.86 131.184 845.299 112.018C898.872 92.0583 949.889 62.9033 1005.65 48.9023C1090.27 27.6523 1174.8 59.8474 1253.79 86.2444C1284.6 96.5424 1319.55 101.485 1346.03 120.769C1412.15 178.749 1390.78 284.916 1379.43 359.442C1370.14 420.422 1363.23 482.157 1333.5 537.697C1309.01 583.451 1274.66 624.761 1232.34 656.151C1183.88 692.091 1126.58 715.261 1068.53 732.291C980.874 758.011 889.088 770.851 797.848 776.531C638.431 783.801 463.68 779.321 309.764 734.501C260.473 720.141 213.957 699.251 167.559 677.931C95.8313 644.981 15.5008 606.761 -24.1516 536.45C-57.6526 477.05 -61.6676 406.004 -59.5266 339.81C-58.1616 304.485 -56.8086 268.253 -47.3676 233.962"
            fill="#EA9CA0"
          />
          <motion.path
            animate={{ x: 25 }}
            transition={{
              ease: "linear",
              duration: "2",
              repeat: Infinity,
              repeatType: "reverse",
            }}
            id="LineWave"
            d="M-22.3656 163.937C22.4964 175.587 67.9332 179.976 113.589 169.252C150.198 160.653 183.553 143.492 217.182 127.609C263.15 105.898 311.116 85.6573 362.496 80.4453C425.961 74.0063 483.486 94.4703 542.981 112.548C589.963 126.825 637.642 138.803 687.017 141.103C742.061 141.235 793.86 131.184 845.299 112.018C898.872 92.0583 949.889 62.9033 1005.65 48.9023C1090.27 27.6523 1174.8 59.8474 1253.79 86.2444C1284.6 96.5424 1319.55 101.485 1346.03 120.769C1412.15 178.749 1390.78 284.916 1379.43 359.442C1370.14 420.422 1363.23 482.157 1333.5 537.697C1309.01 583.451 1274.66 624.761 1232.34 656.151C1183.88 692.091 1126.58 715.261 1068.53 732.291C980.874 758.011 889.088 770.851 797.848 776.531C638.431 783.801 463.68 779.321 309.764 734.501C260.473 720.141 213.957 699.251 167.559 677.931C95.8313 644.981 15.5008 606.761 -24.1516 536.45C-57.6526 477.05 -61.6676 406.004 -59.5266 339.81C-58.1616 304.485 -56.8086 268.253 -47.3676 233.962"
            stroke="white"
            stroke-width="5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_19_2">
            <rect width="1200" height="300" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
