import { motion } from "framer-motion";

export default function AnimationJellyfish() {
  return (
    <div>
      <motion.svg
        style={{
          width: "200px",
          position: "absolute",
          left: "5%",
          top: "40%",
          zIndex: "1",
        }}
        animate={{ y: [15, -15] }}
        transition={{
          ease: "linear",
          duration: "2",
          repeat: Infinity,
          repeatType: "reverse",
        }}
        fill="#ffffff"
        width="200px"
        height="200px"
        viewBox="0 0 128 128"
        data-name="Layer 1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#ffffff"
        transform="matrix(1, 0, 0, 1, 0, 0)"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="#CCCCCC"
          stroke-width="2.816"
        />

        <g id="SVGRepo_iconCarrier">
          <title />

          <path d="M106.7,71.4H95.824A5.227,5.227,0,0,0,90.6,76.623v7.842a1.045,1.045,0,0,1-1.044,1.044H88.87V68.724a10.4,10.4,0,0,0,9.481-10.343v-18.8a38.645,38.645,0,1,0-77.291,0V58.37a10.384,10.384,0,0,0,9.356,10.348V90.281H26.281A5.227,5.227,0,0,0,21.06,95.5v10a1.044,1.044,0,0,0,1.044,1.044,5.834,5.834,0,0,0,5.827-5.827V98.185a1.046,1.046,0,0,1,1.044-1.044h1.441v26.815A1.044,1.044,0,0,0,31.46,125a5.822,5.822,0,0,0,5.816-5.816V97.141h3.415a5.2,5.2,0,0,0,5.19-5.189V54.657a3.617,3.617,0,0,1,1.734.593v68.706A1.044,1.044,0,0,0,48.658,125a5.822,5.822,0,0,0,5.816-5.816V67.355a10.366,10.366,0,0,0,10.337.069v51.76A5.823,5.823,0,0,0,70.628,125a1.044,1.044,0,0,0,1.044-1.044V55.344a3.669,3.669,0,0,1,1.734-.673V87.148a5.227,5.227,0,0,0,5.221,5.221h3.382v26.815A5.823,5.823,0,0,0,87.825,125a1.044,1.044,0,0,0,1.044-1.044V92.369h3.373a5.227,5.227,0,0,0,5.221-5.221V79.307a1.045,1.045,0,0,1,1.044-1.044h3.424a5.823,5.823,0,0,0,5.816-5.816A1.044,1.044,0,0,0,106.7,71.4ZM39.01,89.237a1.046,1.046,0,0,1-1.044,1.044h-.689V66.967A10.577,10.577,0,0,0,39.01,65.5Zm38.071-68.2a2.138,2.138,0,0,1-.908,1.4,2.117,2.117,0,0,1-1.243.4,2.188,2.188,0,1,1,2.151-1.8Zm-1.8-4.125a2.975,2.975,0,1,1,4.145.752,3.006,3.006,0,0,1-1.7.533A2.963,2.963,0,0,1,75.285,16.916Zm6.724,68.593H81.32a1.045,1.045,0,0,1-1.044-1.044v-19.1A10.652,10.652,0,0,0,82.009,66.9Zm4.355-63.184a3.986,3.986,0,1,1-.647-2.986A3.957,3.957,0,0,1,86.364,22.325Z" />
        </g>
      </motion.svg>
    </div>
  );
}
