import { motion } from "framer-motion";

export default function AnimationOctopus() {
  return (
    <div>
      <motion.svg
        style={{
          width: "200px",
          position: "absolute",
          right: "5%",
          top: "30%",
          zIndex: "1",
        }}
        animate={{ y: [15, -15] }}
        transition={{
          ease: "linear",
          duration: "2",
          repeat: Infinity,
          repeatType: "reverse",
        }}
        fill="#fff"
        width="200px"
        height="200px"
        viewBox="0 0 128 128"
        data-name="Layer 1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title />
        <path d="M42.5,44.06a5.455,5.455,0,1,0,5.45,5.46A5.458,5.458,0,0,0,42.5,44.06Zm0,8.91a3.455,3.455,0,1,1,3.45-3.45A3.457,3.457,0,0,1,42.5,52.97Z" />
        <path d="M87.5,44.06a5.455,5.455,0,1,0,5.45,5.46A5.458,5.458,0,0,0,87.5,44.06Zm0,8.91a3.455,3.455,0,1,1,3.45-3.45A3.457,3.457,0,0,1,87.5,52.97Z" />
        <path d="M109.97,62.04H98.15a5,5,0,0,0-5,5V79.07a1,1,0,0,1-1,1H89.5a1,1,0,0,1-1-1V52.8a3.4,3.4,0,0,1-1,.166,3.455,3.455,0,0,1,0-6.91,3.393,3.393,0,0,1,1,.166V44.16l-.046-.007A16.787,16.787,0,0,0,97.5,29.28V16.62A16.806,16.806,0,0,0,80.71-.17H49.16A16.8,16.8,0,0,0,32.38,16.62V29.28a16.782,16.782,0,0,0,9.407,15.052.984.984,0,0,0-.267.528.388.388,0,0,0-.02.15v1.216a3.393,3.393,0,0,1,1-.166,3.455,3.455,0,0,1,0,6.91,3.4,3.4,0,0,1-1-.166V70.17a1,1,0,0,1-1,1H37.85a1,1,0,0,1-1-1V60.03a5,5,0,0,0-5-5h-14a1,1,0,0,0-1,1,7.857,7.857,0,0,0,7.85,7.85H27a1,1,0,0,1,1,1V75.02a5,5,0,0,0,5,5h8.5V89H33a5,5,0,0,0-5,5v22.98a1,1,0,0,0,1,1,7.857,7.857,0,0,0,7.85-7.85V98.85a1,1,0,0,1,1-1H41.5v28.11a1,1,0,0,0,1,1,7.857,7.857,0,0,0,7.85-7.85V97.85h3.87v28.11a1,1,0,0,0,1,1,7.857,7.857,0,0,0,7.85-7.85V65.85a1.93,1.93,0,1,1,3.86,0v53.26a7.857,7.857,0,0,0,7.85,7.85,1,1,0,0,0,1-1V104.48h3.87v14.63a7.857,7.857,0,0,0,7.85,7.85,1,1,0,0,0,1-1V104.48h3.65a1,1,0,0,1,1,1v4.65a7.857,7.857,0,0,0,7.85,7.85,1,1,0,0,0,1-1V100.63a5,5,0,0,0-5-5H88.5V88.92H97a5,5,0,0,0,5-5V71.89a1,1,0,0,1,1-1h.12a7.857,7.857,0,0,0,7.85-7.85A1,1,0,0,0,109.97,62.04ZM40.5,22.88a2.85,2.85,0,1,1-2.85,2.85A2.849,2.849,0,0,1,40.5,22.88Zm-.22,14.27a3.825,3.825,0,1,1,3.82-3.82A3.82,3.82,0,0,1,40.28,37.15Zm4.21-15.67a3.82,3.82,0,1,1,3.82,3.83A3.826,3.826,0,0,1,44.49,21.48Zm.63,9.02a2.1,2.1,0,1,1,2.1,2.1A2.1,2.1,0,0,1,45.12,30.5ZM48.8,40.85A2.85,2.85,0,1,1,51.66,38,2.855,2.855,0,0,1,48.8,40.85ZM54.22,88a1,1,0,0,1-1,1H50.35V65.85a1.935,1.935,0,0,1,3.87,0ZM75.58,55.85a1.945,1.945,0,1,1,1.95-1.94A1.942,1.942,0,0,1,75.58,55.85Zm4.07,39.78H76.78a1,1,0,0,1-1-1V65.85a1.935,1.935,0,0,1,3.87,0ZM77.86,52.17a2.395,2.395,0,1,1,2.4-2.4A2.4,2.4,0,0,1,77.86,52.17Z" />
        <circle cx="42.5" cy="49.516" r="1.336" />
        <circle cx="87.5" cy="49.516" r="1.336" />
      </motion.svg>
    </div>
  );
}
